<template>
  <div style="border: 1px solid #eee;">
    <v-card tile flat color="grey lighten-5">
      <v-sheet tile :color="color ? color : 'grey lighten-4'" class="px-2 py-0">
        <v-icon small>{{ icon }}</v-icon>
        <span class="px-1" style="font-size: 10pt">{{ title }}</span>
      </v-sheet>
      <vue-apex-charts
        :height="height"
        :type="type"
        :options="source.chartOptions"
        :series="source.series"
      ></vue-apex-charts>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "Chart",
  components: { VueApexCharts },
  model: {
    prop: "source",
    event: "input",
  },
  props: {
    source: Object,
    title: String,
    height: String,
    icon: String,
    type: String,
    color: String,
  },
};
</script>

<style scoped>
  .apexcharts-toolbar {
    z-index: 10;
  }
</style>