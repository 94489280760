<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row>
      <v-col cols="12" sm="4">
        <chart
          :source="loadIncidentByType()"
          :title="$t('si.reportsByIncidentType')"
          icon="mdi-chart-bar"
          height="335"
        />
        <v-alert
          icon="mdi-information-outline"
          class="pa-1 mt-0"
          color="grey lighten-4"
          style="font-size: 8pt; color: gray"
          tile
        >
          {{ $t('si.reportsByIncidentType') }}
        </v-alert>
      </v-col>
      <!-- <v-col cols="12" sm="3">
        <chart
          :source="loadStatus()"
          :title="$t('dashboard.reportsByStatus')"
          icon="mdi-chart-bar"
          height="287"
        />
        <v-alert
          icon="mdi-information-outline"
          class="pa-1 mt-0"
          color="grey lighten-4"
          style="font-size: 8pt; color: gray"
          tile
        >
          Reported issues by report status.
        </v-alert>
      </v-col> -->
      <!-- <v-col cols="12" sm="5">
        <chart
          :source="loadSubCategory()"
          :title="$t('dashboard.reportsBySubCategory')"
          icon="mdi-chart-bar"
          height="287"
        />
        <v-alert
          icon="mdi-information-outline"
          class="pa-1 mt-0"
          color="grey lighten-4"
          style="font-size: 8pt; color: gray"
          tile
        >
          Reported issues by feedback sub-category.
        </v-alert>
      </v-col> -->
    </v-row>

    <!-- <v-row>
      <v-col cols="12">
        <pivot-table
          :source="loadPivot()"
          :title="$t('general.totals')"
          icon="mdi-sigma"
        />
        <v-alert
          icon="mdi-information-outline"
          class="py-0 px-1 mt-0"
          color="grey lighten-4"
          style="font-size: 8pt; color: gray;"
        >
          Totals of visa requests by status, type and type of authority.
        </v-alert>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import Chart from "@/views/dashboard/visuals/Chart";
// import PivotTable from "@/views/dashboard/visuals/PivotTable";
import dashboardMixin from "@/views/dashboard/dashboard.mixin";
import { mapActions, mapGetters } from "vuex";
// import _ from "lodash";

export default {
  name: "SrsIndicators",
  mixins: [dashboardMixin],
  components: {
    Chart,
    // PivotTable,
    // VueApexChartsChart,
  },
  model: {
    prop: "source",
    event: "input",
  },
  props: {
    source: Array,
  },
  data() {
    return {
      aggr: {
        sum: "sum",
        normalCount: "normalCount",
        count: "count",
        avg: "avg",
        max: "max",
        min: "min",
      },
    };
  },
  computed: {
    ...mapGetters("types", ["getTypeByGroupAndName"]),
  },
  methods: {
    ...mapActions("ui", ["setLoadingState", "clearLoadingState"]),

    loadIncidentByType() {
      this.setLoadingState("Building dashboard components...");
      // const dataCats = [
      //   { name: "Armed Conflict", keys: [this.getTypeByGroupAndName("SRS_INCIDENT_TYPE", "Armed Conflict")?.id.toString()] },
      //   { name: "Crime", keys: [this.getTypeByGroupAndName("SRS_INCIDENT_TYPE", "Crime")?.id.toString()] },
      //   { name: "Hazards", keys: [this.getTypeByGroupAndName("SRS_INCIDENT_TYPE", "Hazards")?.id.toString()] },
      //   { name: "Inhibiting Context", keys: [this.getTypeByGroupAndName("SRS_INCIDENT_TYPE", "Inhibiting Context")?.id.toString()] },
      //   { name: "Civil Unrest", keys: [this.getTypeByGroupAndName("SRS_INCIDENT_TYPE", "Civil Unrest")?.id.toString()] },
      //   { name: "Terrorism", keys: [this.getTypeByGroupAndName("SRS_INCIDENT_TYPE", "Terrorism")?.id.toString()] },
      // ];
      const dataCats = [
        "Armed Conflict",
        "Crime",
        "Hazards",
        "Inhibiting Context",
        "Cancelled",
        "Civil Unrest",
        "Terrorism",
      ];
      const dataSeries = this.generateSeries(
        "incidentTypeName",
        "id",
        this.aggr.count,
        null,
        this.source,
        null,
        dataCats
      );
      const chartOptions = this.generateChartOptions(dataSeries, null, {
        type: "donut",
        horizontal: true,
        colors: ["#01a9f4", "#00E396", "#007F73", "#FF4560", "#891652", "#FEB019", "#87A922"],
        // hideTotal: true,
      });
      this.clearLoadingState();
      return chartOptions;
    },
    loadStatus() {
      this.setLoadingState("Building dashboard components...");
      const dataCats = [
        { name: "Closed", keys: [this.getTypeByGroupAndName("SRS_STATUS", "Closed")?.id.toString()] },
        { name: "Open", keys: [this.getTypeByGroupAndName("SRS_STATUS", "Open")?.id.toString()] },
        { name: "Pending delivery of response", keys: [this.getTypeByGroupAndName("SRS_STATUS", "Pending delivery of response")?.id.toString()] },
      ];
      const ds = this.source ? this.source.filter((f) => f.status > 0) : [];
      const dataSeries = this.generateSeries(
        "status",
        "value",
        this.aggr.sum,
        null,
        ds,
        [
          { key: "status", value: this.getTypeByGroupAndName("SRS_STATUS", "Closed")?.id, name: "Closed" },
          { key: "status", value: this.getTypeByGroupAndName("SRS_STATUS", "Open")?.id, name: "Open" },
          { key: "status", value: this.getTypeByGroupAndName("SRS_STATUS", "Pending delivery of response")?.id, name: "Pending delivery of response" },
        ],
        // null,
        dataCats
      );
      const chartOptions = this.generateChartOptions(dataSeries, null, {
        type: "bar",
        // horizontal: true,
        stacked: true,
        colors: ["#01a9f4", "#FF4560", "#FEB019"],
        hideCategory: true,
      });

      this.clearLoadingState();
      return chartOptions;
    },
    loadSubCategory() {
      this.setLoadingState("Building dashboard components...");
      const dataCats = [
      { name: "Complaint about targeting criteria", keys: [this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "Complaint about targeting criteria")?.id.toString()] },
      { name: "General program enquiry", keys: [this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "General program enquiry")?.id.toString()] },
      { name: "New request", keys: [this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "New request")?.id.toString()] },
      { name: "Request for specialised support services", keys: [this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "Request for specialised support services")?.id.toString()] },
      { name: "Request to supplement/alter existing assistance", keys: [this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "Request to supplement/alter existing assistance")?.id.toString()] },
      { name: "Specific assistance enquiry", keys: [this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "Specific assistance enquiry")?.id.toString()] },
      { name: "Assistance Quantity", keys: [this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "Assistance Quantity")?.id.toString()] },
      { name: "Assistance Quality", keys: [this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "Assistance Quality")?.id.toString()] },
      { name: "Community Selection", keys: [this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "Community Selection")?.id.toString()] },
      { name: "Assistance Timing", keys: [this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "Assistance Timing")?.id.toString()] },
      { name: "Conflict of Interest", keys: [this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "Conflict of Interest")?.id.toString()] },
      { name: "Other", keys: [this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "Assistance Timing")?.id.toString()] },
      { name: "Unspecified", keys: [this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "Unspecified")?.id.toString()] },
      ];
      const ds = this.source ? this.source.filter((f) => f.subCategory > 0) : [];
      const dataSeries = this.generateSeries(
        "subCategory",
        "value",
        this.aggr.sum,
        null,
        ds,
        [
          {
            key: "subCategory",
            value: this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "Complaint about targeting criteria")?.id,
            name: "Targeting Criteria",
          },
          {
            key: "subCategory",
            value: this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "General program enquiry")?.id,
            name: "Program Enquiry",
          },
          {
            key: "subCategory",
            value: this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "New request")?.id,
            name: "New Request",
          },
          {
            key: "subCategory",
            value: this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "Request for specialised support services")?.id,
            name: "Support Services",
          },
          {
            key: "subCategory",
            value: this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "Request to supplement/alter existing assistance")?.id,
            name: "Supplement/Alter Existing Assistance",
          },
          {
            key: "subCategory",
            value: this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "Specific assistance enquiry")?.id,
            name: "Specific Assistance Enquiry",
          },

          {
            key: "subCategory",
            value: this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "Assistance Quantity")?.id,
            name: "Assistance Quantity",
          },
          {
            key: "subCategory",
            value: this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "Community Selection")?.id,
            name: "Community Selection",
          },
          {
            key: "subCategory",
            value: this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "Assistance Quality")?.id,
            name: "Assistance Quality",
          },
          {
            key: "subCategory",
            value: this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "Assistance Timing")?.id,
            name: "Assistance Timing",
          },
          {
            key: "subCategory",
            value: this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "Conflict of Interest")?.id,
            name: "Conflict of Interest",
          },
          {
            key: "subCategory",
            value: this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "Other")?.id,
            name: "Other"
          },
          {
            key: "subCategory",
            value: this.getTypeByGroupAndName("SRS_SUB_CATEGORY", "Unspecified")?.id,
            name: "Unspecified",
          },
        ],
        // null,
        dataCats
      );
      const chartOptions = this.generateChartOptions(dataSeries, null, {
        // type: "donut",
        horizontal: true,
        stacked: true,
        colors: [
          "#01a9f4",
          "#AF0171",
          "#00E396",
          "#C060A1",
          "#FEB019",
          "#FF7000",
        ],
        hideCategory: true,
        hideX: true,
        textDataLabels: true,
      });

      this.clearLoadingState();
      return chartOptions;
    },

    loadPivot() {
      const pivotStatusMeta = {
        rowAttr: "Visa Residency Type",
        rows: [
          "Visitor Visa",
          "Residency",
          "Work Visa",
          "Visa",
          "Exit & Reentry visa",
          "Entry visa",
          "Visit visa",
        ],
        cols: [
          {
            valueAttribute: "Id",
            aggregationMethod: this.aggr.count,
            name: "Count",
          },
        ],
        groupBy: [
          { key: "Auth. Type", value: "IRG", name: "IRG" },
          { key: "Auth. Type", value: "DFA", name: "DFA" },
        ],
      };

      const pivot = this.createPivot(pivotStatusMeta, this.source);
      return pivot;
    },
  },
};
</script>
