<template>
  <!-- class="overflow-y-auto py-0" -->
  <v-container
    fluid
    class="docHolder overflow-y-auto overflow-x-hidden pa-0 ma-0"
    :style="getContainerStyle"
  >
    <incidents-filter
      v-model="filter"
      @clear="clearFilter"
      @filter="loadItems"
    />
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      color="primary"
      class="no-print"
    ></v-progress-linear>

    <!-- visuals -->
    <v-card flat id="dashboard" ref="dashboard" class="pa-0 ma-2">
      <div class="printablePage">
        <v-sheet
          color="grey lighten-3"
          class="px-2 py-0 mt-2 d-flex justify-space-between"
        >
          <span class="title text-left">
            <v-icon>mdi-chart-bar</v-icon>
            {{ `${$t("dashboard.srsDashboard")} (As of: ${getPrintedIn})` }}
          </span>
          <!-- <span class="text-right pa-2" style="font-size: 8pt;">
            | This dashboard reflects those projects with 1 or more
            sub-agreements
            <v-icon small>mdi-information</v-icon>
          </span> -->
        </v-sheet>

        <v-sheet>
          <v-chip
            class="px-2 ma-0 mr-1"
            v-for="(tag, t) in getFilterTags"
            :key="t"
            x-small
            label
          >
            {{ tag }}
          </v-chip>
        </v-sheet>

        <v-sheet
          dark
          style="border-radius: 4px;"
          color="secondary"
          class="px-2 py-0 my-2 d-flex justify-space-between"
        >
          <h2 class="text-left">
            <v-icon>mdi-finance</v-icon>
            {{ $t("srs.incidents") }}
          </h2>
        </v-sheet>

        <h4
          class="px-2 py-0 primary white--text"
          style="border-top-left-radius: 4px; border-top-right-radius: 4px"
        >
          {{
            $t("srs.incidents")
          }}
        </h4>
        <!-- <v-progress-linear
          color="light-blue"
          height="10"
          :value="datasets.informationRequestIndPerc"
          striped
        ></v-progress-linear> -->
        <srs-indicators class="mt-5" v-model="datasets.incidents" />

        <!-- <v-divider />
        <h4
          class="px-2 py-0 primary white--text"
          style="border-top-left-radius: 4px; border-top-right-radius: 4px"
        >
          {{
            $t("dashboard.requestForAssistance") +
            ` (${datasets.requestsForAssistanceIndPerc}%)`
          }}
        </h4>
        <v-progress-linear
          color="light-blue"
          height="10"
          :value="datasets.requestsForAssistanceIndPerc"
          striped
        ></v-progress-linear>
        <srs-indicators
          class="mt-1"
          v-model="datasets.requestsForAssistanceInd"
        />

        <v-divider />
        <h4
          class="px-2 py-0 primary white--text"
          style="border-top-left-radius: 4px; border-top-right-radius: 4px"
        >
          {{ $t("dashboard.complaint") + ` (${datasets.complaintIndPerc}%)` }}
        </h4>
        <v-progress-linear
          color="light-blue"
          height="10"
          :value="datasets.complaintIndPerc"
          striped
        ></v-progress-linear>
        <srs-indicators class="mt-1" v-model="datasets.complaintInd" />

        <v-divider />
        <h4
          class="px-2 py-0 primary white--text"
          style="border-top-left-radius: 4px; border-top-right-radius: 4px"
        >
          {{ $t("dashboard.feedback") + ` (${datasets.feedbackIndPerc}%)` }}
        </h4>
        <v-progress-linear
          color="light-blue"
          height="10"
          :value="datasets.feedbackIndPerc"
          striped
        ></v-progress-linear>
        <srs-indicators class="mt-1" v-model="datasets.feedbackInd" />

        <v-divider />
        <h4
          class="px-2 py-0 primary white--text"
          style="border-top-left-radius: 4px; border-top-right-radius: 4px"
        >
          {{ $t("dashboard.rumour") + ` (${datasets.rumourIndPerc}%)` }}
        </h4>
        <v-progress-linear
          color="light-blue"
          height="10"
          :value="datasets.rumourIndPerc"
          striped
        ></v-progress-linear>
        <srs-indicators class="mt-1" v-model="datasets.rumourInd" /> -->
      </div>
    </v-card>

    <v-divider class="no-print" />

    <v-btn
      @click="() => generatePDF()"
      class="no-print ma-2"
      tile
      color="primary"
    >
      Download
      <v-icon> mdi-file-pdf </v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import SrsDashboardFilter from "@/views/components/SrsDashboardFilter";
import IncidentsFilter from "@/features/si/views/IncidentsFilter";
import SrsIndicators from "./visuals/srs/SrsIndicators";
// import html2pdf from "html2pdf.js";
import moment from "moment";
import dashboardMixin from "./dashboard.mixin";

// import _ from "lodash";

export default {
  name: "SrsDashboard",
  components: {
    // SrsDashboardFilter,
    IncidentsFilter,
    SrsIndicators,
  },
  mixins: [dashboardMixin],
  data() {
    return {
      loading: false,
      organization: null,
      dataset: [],
      datasets: {
        incidents: [],
      },
      isAdmin: false,
      printMode: false,
      filter: {
        search: null,
      },
      filterReset: {
        search: null,
      },
    };
  },
  mounted() {
    this.loading = true;
    this.isAdmin = this.$hasRole("Administrator");
    this.loadTypes();

    if (this.isAdmin) {
      this.loadItems();
    // } else {
    //   const { organizationId } = this.$getUserInfo();
    //   this.setLoadingState("Downloading organization dashboard data...");
    //   this.loadOrganizationDashboardSrs(organizationId)
    //     .then((data) => {
    //       this.loadItemsset(data);
    //       this.clearLoadingState();
    //     })
    //     .catch(() => {
    //       this.loading = false;
    //       this.clearLoadingState();
    //     });
    }
  },
  computed: {
    ...mapGetters("srs", ["getSrs", "getSrsReloadMode"]),
    ...mapGetters("types", ["getTypeByGroupAndName"]),

    getPdfName() {
      return `UNDSS SRS - Dashboard (As of: ${this.getPrintedIn})`;
    },
    getPrintedIn() {
      return moment().format("MMM Do, YYYY");
    },
    getFilterTags() {
      const tags = [];
      if (this.filter.from) {
        tags.push("From: " + this.filter.from);
      }
      if (this.filter.to) {
        tags.push("To: " + this.filter.to);
      }
      return tags;
    },
    getContainerStyle() {
      return !this.printMode
        ? "direction: ltr; " + this.viewHeight(54)
        : "";
    },
  },
  methods: {
    ...mapActions("types", ["loadTypes"]),
    ...mapActions("ui", [
      "setBreadcrumbs",
      "setLoadingState",
      "clearLoadingState",
    ]),
    ...mapActions("incidents", [
      "loadIncidentsByParams",
      "loadPrivateIncidentsByParams",
    ]),

    clearFilter() {
      this.filter = { ...this.filterReset };
    },
    safePerc(a, b) {
      const val = b > 0 ? (a / b) * 100 : 0;
      return val.toLocaleString("en", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      });
    },
    filterDataset() {
      this.loading = true;
      this.setLoadingState("Processing dashboard data...");

      console.log(this.dataset);
      if (this.notEmptyArray(this.dataset)) {
        // let filtered = this.dataset.filter((f) => {
        //   return (
        //     (!f.dateTimeOfIncident ||
        //       ((!this.filter.from ||
        //         moment(f.dateTimeOfIncident).isSameOrAfter(this.filter.from)) &&
        //         (!this.filter.to ||
        //           moment(f.dateTimeOfIncident).isSameOrBefore(this.filter.to))))
        //   );
        // });

        this.datasets.incidents = this.dataset;


        // this.datasets.informationRequestInd = filtered.filter(
        //   (f) =>
        //     f.feedbackCategory ===
        //     this.getTypeByGroupAndName(
        //       "SRS_FEEDBACK_CATEGORY",
        //       "Information Request"
        //     )?.id
        // );
        // this.datasets.requestsForAssistanceInd = filtered.filter(
        //   (f) =>
        //     f.feedbackCategory ===
        //     this.getTypeByGroupAndName(
        //       "SRS_FEEDBACK_CATEGORY",
        //       "Request for Assistance"
        //     )?.id
        // );
        // this.datasets.complaintInd = filtered.filter(
        //   (f) =>
        //     f.feedbackCategory ===
        //     this.getTypeByGroupAndName("SRS_FEEDBACK_CATEGORY", "Complaint")?.id
        // );
        // this.datasets.feedbackInd = filtered.filter(
        //   (f) =>
        //     f.feedbackCategory ===
        //     this.getTypeByGroupAndName("SRS_FEEDBACK_CATEGORY", "Feedback")?.id
        // );
        // this.datasets.rumourInd = filtered.filter(
        //   (f) =>
        //     f.feedbackCategory ===
        //     this.getTypeByGroupAndName("SRS_FEEDBACK_CATEGORY", "Rumour")?.id
        // );

        // const total = filtered.value;

        // if (total > 0) {
        //   this.datasets.informationRequestIndPerc = this.safePerc(
        //     _(this.datasets.informationRequestInd)
        //       .filter((f) => !!f.status)
        //       .sumBy((o) => o.value),
        //     total
        //   );

        //   this.datasets.requestsForAssistanceIndPerc = this.safePerc(
        //     _(this.datasets.requestsForAssistanceInd)
        //       .filter((f) => !!f.status)
        //       .sumBy((o) => o.value),
        //     total
        //   );

        //   this.datasets.complaintIndPerc = this.safePerc(
        //     _(this.datasets.complaintInd)
        //       .filter((f) => !!f.status)
        //       .sumBy((o) => o.value),
        //     total
        //   );

        //   this.datasets.feedbackIndPerc = this.safePerc(
        //     _(this.datasets.feedbackInd)
        //       .filter((f) => !!f.status)
        //       .sumBy((o) => o.value),
        //     total
        //   );
        //   this.datasets.rumourIndPerc = this.safePerc(
        //     _(this.datasets.rumourInd)
        //       .filter((f) => !!f.status)
        //       .sumBy((o) => o.value),
        //     total
        //   );
        // }
      }

      this.loading = false;
      this.clearLoadingState();
    },
    generatePDF() {
      this.printMode = true;
      setTimeout(() => {
        window.print();
        this.printMode = false;
      }, 500);
    },
    loadItems() {
      this.setLoadingState("Downloading dashboard data...");
      this.loadIncidentsByParams(this.filter)
        .then((data) => {
          this.datasets.incidents = data.content;
          this.loading = false;
          this.clearLoadingState();
        })
        .catch(() => {
          this.loading = false;
          this.clearLoadingState();
        });
    },
  },
};
</script>

<style>
@media print {
  main {
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
  }

  .docHolder {
    min-height: 2000px !important;
    overflow: visible !important;
  }

  header,
  footer,
  .v-app-bar,
  .v-navigation-drawer,
  .filter,
  .v-toolbar,
  .no-print {
    display: none !important;
  }

  .apexcharts-toolbar {
    z-index: 1;
  }

  #dashboard {
    padding: 0;
    margin: 0;
  }

  #dashboard .printablePage:not(:last-child) {
    display: block !important;
    position: relative !important;
    z-index: 9999 !important;
    visibility: visible !important;
    background-color: #fff !important;
    height: 100% !important;
    width: 100% !important;

    padding: 0px;
    margin: 0 !important;
    orphans: 0 !important;
    widows: 0 !important;
    overflow: visible !important;
    page-break-after: always;
  }

  @page {
    size: A4;
    margin: 0.5cm;
    orphans: 0 !important;
    widows: 0 !important;
    scale: 55;
  }
}
</style>
