// import moment from "moment";
import _ from "lodash";
import moment from "moment";

export default {
  methods: {
    selectFrom(data, keys) {
      // select columns from data

      // const selected = data.map((m) =>
      //   _.pick(
      //     m,
      //     keys.map((k) => {
      //       // const matchProp = regexpProp.exec(k);
      //       const p = k.indexOf("->");
      //       return k.indexOf("->") !== -1 && k.indexOf("(") === -1 ? k.substring(0, p) : k;
      //     })
      //   )
      // );
      const nKeys = keys.filter((k) => k.indexOf("->") === -1);
      const pKeys = keys.filter((k) => k.indexOf("->") !== -1);
      const selected = data.map((row) => {
        const item = _.pick(row, nKeys);
        for (let k in pKeys) {
          const key = pKeys[k];
          let label = key;

          const regexpProp = /((?<aggr>\w+)=>)*(?<obj>\w+)->(?<prop>\w+)(:(?<label>.+))*/gi;
          const matchProp = regexpProp.exec(key);
          if (matchProp) {
            if (matchProp.groups.label) {
              label = matchProp.groups.label;
            } else if (matchProp.groups.aggr) {
              label = `${matchProp.groups.aggr}:${key}`;
            }

            const parentKey = matchProp.groups.obj;
            const childKey = matchProp.groups.prop;

            if (this.notEmptyArray(row[parentKey])) {
              for (let childIndex in row[parentKey]) {
                const child = row[parentKey][childIndex];
                item[label] = matchProp.groups.aggr
                  ? this.aggregate(
                      row[parentKey],
                      childKey,
                      matchProp.groups.aggr
                    )
                  : child[childKey];
              }
            } else {
              item[label] = [];
            }
          } else {
            item[label] = row[key];
          }
        }
        return item;
      });

      // select sub-columns from object column
      // const result = [];
      // const objKeys = keys.filter((f) => f.indexOf("->") !== -1);
      // for (let r in selected) {
      //   const row = selected[r];
      //   const temp = {};
      //   for (let k in objKeys) {
      //     const key = objKeys[k];
      //     const matchProp = regexpProp.exec(key);
      //     const matchAggr = regexpAggr.exec(key);
      //     const matchLabel = regexpLabel.exec(key);

      //     if (matchProp) {
      //       const parentKey = matchProp.groups.obj;
      //       const childKey = matchProp.groups.prop;

      //       for (let c in row[parentKey]) {
      //         let label = key;
      //         if (matchLabel) {
      //           label = matchLabel.groups.label;
      //         } else if (matchAggr) {
      //           label = `${matchAggr.groups.aggr}:${key}`;
      //         }

      //         const child = row[parentKey][c];
      //         temp[label] = matchAggr
      //           ? this.aggregate(
      //               row[parentKey],
      //               childKey,
      //               matchAggr.groups.aggr
      //             )
      //           : child[childKey];
      //       }
      //     }
      //   }
      //   result.push({ ...row, ...temp });
      // }

      // return result;
      return selected;
    },
    generateSeries(
      keyAttribute,
      valueAttribute,
      aggregationMethod,
      accumulation,
      source,
      series,
      categories,
      filter
    ) {
      const filtered = filter && source ? _.filter(source, filter) : source;
      const groups = _.groupBy(filtered, keyAttribute);
      const dataSeries = [];
      if (!series) {
        dataSeries.push(
          this.extractSeries(
            valueAttribute,
            filtered,
            groups,
            valueAttribute,
            aggregationMethod,
            categories,
            accumulation
          )
        );
      } else {
        for (var i = 0; i < series.length; i++) {
          const s = filtered?.filter(
            (f) => f[series[i].key] === series[i].value
          );
          const sGroups = _.groupBy(s, keyAttribute);
          // dataSeries.push(extractSeries(series[i].name, sgroups));
          dataSeries.push(
            this.extractSeries(
              series[i].name,
              filtered,
              sGroups,
              valueAttribute,
              aggregationMethod,
              categories,
              accumulation
            )
          );
        }
      }

      return dataSeries;
    },
    kmLabelFormatter(v) {
      if (!v) {
        return;
      }

      if (typeof v !== "string") {
        if (v % 1 != 0) {
          v = parseFloat(v.toLocaleString());
        }

        return v > 999999 ? v / 1000000 + "M" : v > 999 ? v / 1000 + "K" : v;
      } else {
        return v.length > 10 ? v.substring(0, 9) + "..." : v;
      }
    },
    generateChartOptions(dataSeries, categories, options) {
      return {
        series:
          options && (options.type === "pie" || options.type === "donut")
            ? dataSeries[0].values
            : dataSeries.map((m) => ({
                name: m.name,
                data: m.values.map((v) => (v === 0 ? null : v)),
              })),
        chartOptions: {
          chart: {
            background:
              options && options.background ? options.background : null,
            type: options && options.type ? options.type : "bar",
            stacked: options && options.stacked,
            stackType: options && !options.hidePercentage,
            toolbar: {
              show: true,
            },
            zoom: {
              enabled: false,
            },
          },
          legend: {
            show: true,
            position: "bottom",
            fontSize: '9px',
          },
          plotOptions: {
            bar: {
              horizontal: options && options.horizontal,
              distributed: true,
              dataLabels: {
                position: 'bottom',
                hideOverflowingLabels: false,
              },
            },
            pie: {
              donut: {
                size: "40%",
                labels: {
                  show: true,
                  name: {
                    show: true,
                    formatter: (v) => v,
                  },
                  color: "white",
                  value: {
                    show: true,
                    offsetY: -7,
                    formatter: (v) => v,
                  },
                  total: {
                    show: !(options && options.hideTotal),
                    label:
                      options && options.totalLabel
                        ? options.totalLabel
                        : options && options.grandAverage
                        ? "Grand Average"
                        : "Total",
                    showAlways: true,
                    fontSize: "10px",
                    // fontWeight: 700,
                    formatter: (w) => {
                      if (options && options.total) {
                        return options.total;
                      }

                      const tot =
                        options && options.grandAverage
                          ? Math.round(_.mean(w.globals.seriesTotals))
                          : _.sum(w.globals.seriesTotals);
                      // const tot2 = this.kmLabelFormatter(tot);
                      return tot.toLocaleString("en", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3,
                      });
                    },
                  },
                },
              },
            },
          },
          dataLabels: {
            enabled: true, //options.type !== "line",
            hideOverflowingLabels: false,
            textAnchor: options && options.horizontal ? "start" : "middle",
            offsetX: 1,
            offsetY: 1,
            dropShadow: {
              enabled: false,
            },
            formatter: (v, opts) => {
              let l, p = 0;

              if (options.type === "donut" || options.type === "pie") {
                l = (v / 100) * _.sum(opts.w.config.series);
                p = v;
              } else {
                l = v;
                p = (v / dataSeries[0].total) * 100;
                // p =
                //   (v / _.sum(opts.w.config.series[opts.seriesIndex].data)) *
                //   100;
              }

              return `${
                v && options && options.textDataLabels
                  ? opts.w.config.series[opts.seriesIndex].name + ": "
                  : ""
              }
              ${l > 0 ? l.toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 1, }): ""}
              ${!options.hidePercentage && p > 0 ? " (" + p.toLocaleString("en", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
              }) + "%)" : ""}`;
            },
            style: {
              fontSize: "10px",
              colors: options.type !== "line" ? ["#000"] : undefined,
            },
            // offsetX: options && options.horizontal ? 0 : -20,
          },
          labels: categories ? categories : dataSeries[0].categories,
          stroke: {
            curve: "straight",
            width: 2,
          },
          markers: {
            size: 8,
          },
          xaxis: {
            categories: categories ? categories : dataSeries[0].categories,
            labels: {
              show: !options || !options.hideX,
              // hideOverlappingLabels: false,
              style: {
                fontSize: "10px",
              },
              formatter: this.kmLabelFormatter,
            },
          },
          yaxis: {
            show: options && options.hideCategory ? false : true,
            logarithmic: options && options.logarithmic,
            labels: {
              formatter: this.kmLabelFormatter,
            },
          },
          colors:
            options && options.colors
              ? options.colors
              : ["#2E93fA", "#66DA26", "#546E7A", "#E91E63", "#FF9800"],
        },
      };
    },
    extractSeries(
      name,
      data,
      groups,
      valueAttribute,
      aggregationMethod,
      categories,
      accumulation
    ) {
      let vals = [];
      const keys = Object.keys(groups);
      const total = this.aggregate(data, valueAttribute, aggregationMethod);

      if (!accumulation) {
        const valueGroups = _(groups).map((value) =>
          this.aggregate(value, valueAttribute, aggregationMethod)
        );
        vals = valueGroups.value();
      } else {
        const valueGroups = _(groups)
          .map((value, key) => ({
            key,
            value: this.aggregate(value, valueAttribute, aggregationMethod),
          }))
          .value();
        const nullIndex = accumulation.indexOf(null);
        valueGroups.forEach((o, i) => {
          if (i <= nullIndex) {
            vals.push(o.value);
          } else {
            vals.push(
              _(valueGroups)
                .filter(
                  (f, j) =>
                    j > nullIndex &&
                    accumulation.indexOf(f.key) >= accumulation.indexOf(o.key)
                )
                .sumBy("value")
            );
          }
        });
      }

      const cVals = this.categorizeValues(vals, keys, categories);
      const result = {
        name,
        values: cVals.map((m) => m.value),
        categories: cVals.map((m) => m.key),
        total,
      };

      return result;
    },
    aggregate(data, valueAttribute, aggregationMethod) {
      let value = 0;
      if (data && data.length > 0) {
        value =
          aggregationMethod === "count"
            ? _.uniq(_.map(data, (m) => m[valueAttribute])).length
            : aggregationMethod === "normalCount"
            ? _.map(data, (m) => m[valueAttribute]).length
            : aggregationMethod === "sum"
            ? _.sumBy(data, valueAttribute)
            : aggregationMethod === "avg"
            ? _.meanBy(data, valueAttribute)
            : aggregationMethod === "max"
            ? _.maxBy(data, valueAttribute)[valueAttribute]
            : _.minBy(data, valueAttribute)[valueAttribute];
      }

      return value;
    },
    categorizeValues(vals, keys, categories) {
      const groupedKeys =
        this.notEmptyArray(categories) && typeof categories[0] === "object";
      const result = categories.map((m) => ({
        key: groupedKeys ? m.name : m,
        value: 0,
      }));

      if (groupedKeys) {
        keys.forEach((k, i) => {
          const c = categories.find((f) => f.keys && f.keys.includes(k));
          const ci = categories.indexOf(c);
          if (ci >= 0 && result[ci]) {
            result[ci].value += vals[i];
          }
        });
      } else {
        categories.forEach((c, i) => {
          const ci = keys.indexOf(c);
          if (ci >= 0 && result[i]) {
            result[i].value = vals[ci];
          }
        });
      }

      return result;
    },
    extractJsonArray(obj, key, prop) {
      if (!prop) {
        prop = "name_en";
      }

      const arr = obj[key];
      if (arr && arr.length > 0 && arr[0]) {
        return arr.map((m) => m[prop]);
      }
      return [];
    },
    createPivot(meta, data) {
      const result = {
        rowsHeader: meta.rowAttr,
        items: [...meta.rows, "Grand Total"].map((m) => ({
          [meta.rowAttr]: m,
        })),
        colHeaders: meta.cols.map((m) => m.name),
        groupsHeader:
          meta.groupBy && meta.groupBy.length > 0 ? meta.groupBy[0].key : "",
        groupTotals: {},
        formattedGroupTotals: {},
        groups: meta.groupBy ? meta.groupBy.map((m) => m.name) : [],
      };

      const roundAccurately = (number, decimalPlaces) =>
        Number(
          Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces
        ).toLocaleString("en");

      const formatValue = (prefix, v, values) => {
        if (v === 0) {
          return "-";
        }

        const valueAndPrefix = (prefix ? prefix : "") + roundAccurately(v, 2);

        if (!values) {
          return valueAndPrefix;
        }

        const valueAndPrefixAndPercentage =
          valueAndPrefix +
          ` (${roundAccurately((v / _.sum(values)) * 100, 1)}%)`;

        return valueAndPrefixAndPercentage;
      };

      const buildCols = (groupsData, groupName) => {
        for (var i = 0; i < meta.cols.length; i++) {
          const col = meta.cols[i];
          const series = this.extractSeries(
            groupName,
            data,
            groupsData,
            col.valueAttribute,
            col.aggregationMethod,
            meta.rows
          );

          result.items = result.items.map((m, i) => {
            const key = groupName
              ? `${groupName}:${col.valueAttribute}`
              : col.valueAttribute;
            const value =
              m[meta.rowAttr] !== "Grand Total"
                ? series.values[i]
                : _.sum(series.values);
            const valueAndPrefix = formatValue(col.prefix, value);
            // const valueAndPrefixAndPercentage = formatValue(
            //   col.prefix,
            //   value,
            //   series.values
            // );
            const p = value / _.sum(series.values);

            const item = {
              ...m,
              [key]:
                m[meta.rowAttr] !== "Grand Total"
                  ? { v: valueAndPrefix, p }
                  : { v: valueAndPrefix, p: 1 },
            };

            if (!result.groupTotals[col.name]) {
              result.groupTotals[col.name] = Array(result.items.length).fill(0);
            }
            result.groupTotals[col.name][i] += value;
            return item;
          });
        }
      };

      if (meta.groupBy && meta.groupBy.length > 0) {
        meta.groupBy.forEach((groupMeta) => {
          const groupsData = _.groupBy(
            data.filter((f) => f[groupMeta.key] === groupMeta.value),
            meta.rowAttr
          );
          buildCols(groupsData, groupMeta.name);
        });
      } else {
        buildCols(_.groupBy(data, meta.rowAttr));
      }

      // format group totals
      for (let col in result.groupTotals) {
        const values = result.groupTotals[col];
        result.formattedGroupTotals[col] = [];
        for (let i = 0; i <= values.length - 1; i++) {
          const v = values[i];
          const prefix = meta.cols.find((f) => f.name === col).prefix;
          result.formattedGroupTotals[col][i] = formatValue(prefix, v);
          // if (i === values.length - 1) {
          //   result.formattedGroupTotals[col][i] = formatValue(prefix, v);
          // } else {
          //   result.formattedGroupTotals[col][i] = formatValue(
          //     prefix,
          //     v,
          //     values
          //   );
          // }
        }
      }

      return result;
    },
    getQuarterOrMonth(d) {
      if (moment(d).format("YYYY/\\QQ") !== moment().format("YYYY/\\QQ")) {
        return moment(d).format("YYYY/\\QQ");
      }

      return moment(d).format("YYYY/MMM");
    },
  },
};
